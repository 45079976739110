<template>
  <div id="section-content" class="container">
    <div class="bar-back">
      <router-link to="/member/poy"><i class="fas fa-chevron-left"></i> โพยหวย</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
      <div
        class="
          bgwhitealpha
          text-secondary
          shadow-sm
          rounded
          p-2
          px-2
          xtarget
          col-lotto
          d-flex
          flex-row
          justify-content-between
          mb-1
          pb-0
        "
      >
      <div class="lotto-title d-flex flex-row justify-content-between align-items-center w-100">
        <h4 class="mr-1"><i class="fas fa-receipt"></i> รายละเอียดโพย</h4>
          <a  v-on:click="popupDialog()" class="btn btn-sm btn-outline-danger" style="text-decoration: none;" target="_blank" v-if="getCanShowReturnBtn()">
              คืนโพย
          </a>
      </div>

      </div>
      <div class="border border-dark bg-white shadow-sm rounded mb-2" style="overflow: hidden">
        <div v-if="item" class="bg-secondary text-white py-1 px-2 text-center border-bottom border-dark">
          <i class="fas fa-sticky-note"></i> โพยเลขที่ #{{ item.TicketId }}
        </div>
        <div class="bg-light py-1 px-2">
          <div class="row m-0 p-0">
            <div
              class="
                col-12 col-sm-12 col-md-6
                py-1
                border-bottom
                d-flex
                flex-row
                justify-content-between justify-content-sm-between justify-content-md-start
                align-items-center
              "
            >
              <!-- <span class="mr-1">{{ item.TypeBet }}</span> -->
              <span class="badge font-weight-light"> {{ item.title }}</span>
              <span class="font-weight-light"></span>
            </div>
            <div
              class="
                col-12 col-sm-12 col-md-6
                py-1
                border-bottom
                text-secondary text-center text-sm-center text-md-right
              "
            >
              <small class="mr-1">ทำรายการเมื่อ</small>
              <span class="badge"><i class="far fa-calendar-alt"></i> {{ getDate(item.dateBetting) }}</span>
              <span class="badge"><i class="far fa-clock"></i> {{ getTime(item.dateBetting) }} </span>
            </div>
            <div class="col px-0">
              <div class="pl-1 pr-0 text-center w-100">
                <small>ยอดแทง</small>
                <h5 class="font-weight-light text-primary thb">฿ {{ numberWithCommas(item.price) }}</h5>
              </div>
            </div>
            <div class="col px-0">
               <div class="pl-1 pr-0 text-center w-100">
                <small>ผลได้เสีย</small>
                <h5 class="font-weight-light text-primary thb">฿ {{ numberWithCommas(item.WinAmount) }}</h5>
              </div>
            </div>
            <div class="col-12 px-0"></div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <span v-for="(itemList, index) in data_list" :key="index">
          <div :class="data_list.length > 0 ? 'poy-list' : ''" v-if="data_list.length > 0">
            <div class="poy-list-head">
              <div class="poy-status float-left">ลำดับ {{ index+1 }}</div>
              <div class="poy-status notyet" v-if="item.status == 0 || item.status == 1">
                {{ item.statusName }}
              </div>
              <div class="poy-status" :class=" item.status == 2  ? 'lost' : 'success'" v-else-if="item.status == 2">
                {{ item.statusName  }}
              </div>
                <div class="poy-status" :class=" itemList.reward > 0  ? 'success' : 'lost'" v-else>
                {{ itemList.reward > 0 ? 'ถูกรางวัล' : 'ไม่ถูกรางวัล' }}
              </div>
            </div>
            <div class="poy-list-content pb-0">
              <div class="row">
                <div class="col m-0 pl-2 pr-1 pb-1">
                  <div class="poy-type text-center">
                    <h3 class="text-primary">{{ itemList.number }}</h3>
                    <small>{{ type_list[itemList.type_index] }}</small>
                  </div>
                </div>
                <div class="col m-0 pl-1 pr-3 pb-1 border-left">
                  <div class="d-flex justify-content-between border-bottom">
                    <small>เลขที่ออก</small>
                    <span class="number-win">{{ getResultByType(Number(itemList.type_index)) }}</span>
                  </div>
                   <div class="d-flex justify-content-between ">
                    <small>ราคาจ่าย</small>
                    <span class="number-win">฿ {{ itemList.pay }}</span>
                  </div>
                </div>
                <div class="col-12 bg-light border-top m-0 pt-1 d-flex justify-content-between">
                  <div class="d-flex">
                    <small class="mr-2">ราคาแทง:</small>
                    <h5 class="thb" v-if="itemList">฿ {{ numberWithCommas(itemList.price) }}</h5>
                    <h5 class="thb" v-else>฿ 0</h5>
                  </div>
                  <div class="d-flex">
                    <small class="mr-2">ผลได้เสีย:</small>
                    <h5 class="thb" v-if="itemList">฿ {{ numberWithCommas(itemList.reward) }}</h5>
                    <h5 class="thb" v-else>฿ 0</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div v-on:click="popupDialog()"  class=" " v-bind:class="{ show: show_dialog, fade: show_dialog, 'modal-backdrop' : show_dialog,  }" ></div>
      <div class="modal fade modal_confirm_delete " tabindex="-1" role="dialog" v-bind:class="{ show: show_dialog  }"  >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-white">ข้อความแจ้งเตือน</h5>
                    <button v-on:click="popupDialog()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                  <p class="text-white">คุณต้องการคืนโพยเลขที่ #{{ item.TicketId }} ใช่หรือไม่ ?</p>
                  <br>
                  <!-- <h6 style="color:red;">คุณสามารถยกเลิกโพยได้อีก 5 ครั้ง</h6> -->
                </div>
                <div class="modal-footer">
                      <button v-on:click="popupDialog()" type="button" class="btn btn-secondary" data-dismiss="modal">ไม่</button>
                      <button v-on:click="submitReturnTicket()" type="button" class="btn btn-danger btnconfirmdelete">คืนโพย</button>
                </div>
            </div>
        </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { listAllBetHistoryService, listAllBetHistoryServiceById, returnBetServiceById } from '@/services'

export default {
  async created () {
    this.setLoading(true)
    const id = Number(this.$route.params.id)
    this.id = id
    setInterval(() => {
      this.getNow()
    }, 1000)
    this.poyId = this.$route.params.id
    this.callListAllBetHistoryService(this.id)
  },
  methods: {
    ...mapActions(['setLoading', 'fetchResultLotto', 'getInquiryRoundInfomationByID', 'getBalanceInfo']),
    async callListAllBetHistoryService (id) {
      const resp = await listAllBetHistoryService()
      if (resp.status_code === 'SUCCESS') {
        const data = resp.data.map(e => {
          return {
            ...e,
            numberbet: [],
            // numberbet: e.numberbet,
            WinAmount: parseInt(e.WinAmount),
            IsWinPrice: JSON.parse(e.IsWinPrice),
            dateBetting: moment(e.dateBetting)
          }
        })
        const dataTemp = data.find(e => Number(e.TicketId) === Number(id))
        if (dataTemp != null) {
          this.item = dataTemp
        }
        this.callListAllBetHistoryServiceById(this.id)
        this.setLoading(false)
      }
    },
    async callListAllBetHistoryServiceById (id) {
      const respById = await listAllBetHistoryServiceById(id)
      if (respById.status_code === 'SUCCESS') {
        this.data_list = respById.data.list
        this.type_list = respById.data.type_name
        this.lottery_id = respById.data.lottery_id
        await this.getInquiryRoundInfomationByID(this.lottery_id)
        await this.fetchResultLotto(this.lottery_id)
      }
    },
    getDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    getTime (date) {
      return moment(date).format('HH:mm:ss')
    },
    popupDialog () {
      this.show_dialog = !this.show_dialog
    },
    submitReturnTicket () {
      this.setLoading(true)
      this.callSubmitReturnTicket()
    },
    async callSubmitReturnTicket () {
      if (this.enable_del_ticket) {
        this.enable_del_ticket = false
        const respById = await returnBetServiceById(this.item.TicketId)
        if (respById.status_code === 'SUCCESS') {
          const id = Number(this.$route.params.id)
          this.callListAllBetHistoryService(id)
          const respById = await listAllBetHistoryServiceById(id)
          if (respById.status_code === 'SUCCESS') {
            this.data_list = respById.data.list
            this.type_list = respById.data.type_name
            this.lottery_id = respById.data.lottery_id
            await this.getBalanceInfo()
          }
          this.setLoading(false)
          this.show_dialog = !this.show_dialog
          this.alertDisplay()
          this.enable_del_ticket = true
        } else {
          this.callListAllBetHistoryService(this.poyId)
          this.setLoading(false)
          this.show_dialog = !this.show_dialog
          this.enable_del_ticket = true
        }
      }
      await this.getInquiryRoundInfomationByID(this.lottery_id)
    },
    alertDisplay () {
      this.$swal({
        position: 'center',
        icon: 'success',
        title: 'คืนโพยสำเร็จ',
        showConfirmButton: false,
        timer: 1500
      })
    },
    getResultByType (indexType) {
      if (!Number(this.getResultLotto.reward6) > 0) {
        return '-'
      }
      if (this.getResultLotto != null) {
        if (indexType === 0 || indexType === 1) {
          return this.getResultLotto.reward6.substring(this.getResultLotto.reward6.length - 3, this.getResultLotto.reward6.length)
        } else if (indexType === 2) {
          return this.getResultLotto.reward3_f.join(',')
        } else if (indexType === 3) {
          return this.getResultLotto.reward3_d.join(',')
        } else if (indexType === 4) {
          return this.getResultLotto.reward6.substring(this.getResultLotto.reward6.length - 2, this.getResultLotto.reward6.length)
        } else if (indexType === 5) {
          return this.getResultLotto.reward2_d
        } else if (indexType === 6) {
          return this.getResultLotto.reward6.split('').join(',')
        } else if (indexType === 7) {
          return this.getResultLotto.reward2_d.split('').join(',')
        }
      }
      return '-'
    },
    getIsClose (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        return false
      } else {
        return true
      }
    },
    getNow () {
      this.currentDate = moment()
    },
    getCanShowReturnBtn () {
      let status = false
      if (Number(this.item.status) === 0) {
        if (!this.getIsClose(this.getInquiryRoundInfomation.date.date_close) && this.getInquiryRoundInfomation.date.date_close !== 0) {
          if (Number(this.getInquiryRoundInfomation.status) === 1) {
            status = true
          }
        }
      }
      return status
    },
    numberWithCommas (x) {
      return (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  data () {
    return {
      show_dialog: false,
      type_list: [],
      data_list: [],
      poyId: 0,
      list: [],
      item: {},
      lottery_id: 0,
      currentDate: '',
      enable_del_ticket: true
    }
  },
  computed: {
    ...mapGetters(['getResultLotto', 'getInquiryRoundInfomation'])
  }
}
</script>
